import React from "react";
import Api from '../api';
import { Row, Col } from 'reactstrap';
import SimulatorCard from './SimulatorCard';

class SimulatorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  getSimulations(filter) {
    Api.getSimulations(filter)
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            items: result.docs
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  componentDidMount() { 
    this.getSimulations(this.props.filter)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filter !== prevProps.filter) {
      this.getSimulations(this.props.filter);
    }
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <Row>
            {items.map(item => (
              <Col key={item._id} xs="12" sm="6" md="4" className="mb-3">
                <SimulatorCard simulator={item} />
              </Col>
            ))}
          </Row>
        </div>
      );
    }
  }
}

export default SimulatorList
