import React from 'react';
import { Button } from 'reactstrap';

class ScrollTopButton extends React.Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    window.scrollTo(0, 0);
  }

  render () {
    return (
      <Button color="info" onClick={ this.onClick }>Go to top</Button>
    )
  }
}

export default ScrollTopButton