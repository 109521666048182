import jwtDecode from 'jwt-decode';

const checkTokenExpirationMiddleware = store => next => action => {
  if (action.type === 'persist/REHYDRATE') {
    let result = next(action);
    store.dispatch({
      type: "CHECK_JWT_TOKEN"
    })

    return result;
  }

  if (action.type !== 'CHECK_JWT_TOKEN') {
    return next(action);
  }

  let result = next(action)
  const currentState = store.getState()

  if (currentState.auth.token) {
    const jwt = jwtDecode(currentState.auth.token)
    if (jwt.exp < Date.now() / 1000) {
      store.dispatch({
        type: "LOGOUT_SUCCEEDED"
      })
    }
  }

  return result;
};

export default checkTokenExpirationMiddleware