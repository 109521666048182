import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import checkTokenExpirationMiddleware from './redux/checkTokenExpirationMiddleware'

const persistConfig = {
  key: 'simulatorStore',
  storage,
}

const inititalState = {};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  inititalState,
  composeWithDevTools(applyMiddleware(thunk, checkTokenExpirationMiddleware))
);

export const persistor = persistStore(store);

export default store;