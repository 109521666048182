import React from 'react';
//import Header from './Header'
import AppNavbar from '../components/AppNavbar'
import { Container } from 'reactstrap'
import { BrowserRouter as Router } from "react-router-dom";
import {Helmet} from "react-helmet";
import config from 'react-global-configuration';

const Layout = (props) => {
  const appName = config.get('app').name || "App";

  return (
    <Router>
      <Helmet defaultTitle={ appName } titleTemplate={ appName + " - %s" } />
      <AppNavbar />
      <Container fluid={true} className='mt-3'>
        {props.children}
      </Container>
    </Router>
  )
}

export default Layout