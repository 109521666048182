import React from "react";

import SimulatorList from '../components/SimulatorList';
import ScrollTopButton from '../components/ScrollTopButton';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'

class Home extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div>
        <h1>{t('common:home.title')}</h1>
        <p>{t('common:home.welcome')}</p>

        <h3>{this.props.authentication.isAuthenticated?"My simulations":"Latest simulations"}</h3>
        <SimulatorList filter={this.props.authentication.isAuthenticated?"mine":""} />

        <ScrollTopButton />
      </div>
    )
  }
}

const mapStateToProp = storeState => ({
  authentication: storeState.auth
})

export default connect(mapStateToProp)(withTranslation()(Home))