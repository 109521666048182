import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

class LogoutButton extends React.Component {
  constructor() {
    super();

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.dispatch({
      type: "LOGOUT_SUCCEEDED"
    });

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render () {
    const loggedIn = this.props.authentication.isAuthenticated;

    if (!loggedIn) {
      return "";
    } else {
      return (
        <Button color="danger" onClick={ this.onClick }>Logout</Button>
      )
    }
  }
}

const mapStateToProp = storeState => ({
  authentication: storeState.auth
})

export default connect(mapStateToProp)(LogoutButton)