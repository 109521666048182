import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Form } from 'reactstrap';
import config from 'react-global-configuration';

import { Link, NavLink as RouterNavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import SimulatorButtonNew from './SimulatorButtonNew'
import LanguageSwitcher from '../components/LanguageSwitcher'

class AppNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);

    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  close() {
    this.setState({
      isOpen: false
    });
  }

  render() {
    const { t } = this.props;

    return (
      <Navbar color="dark" light expand="md" fixed="top" dark>
        <Link className="navbar-brand" to="/" onClick={this.close}>Simulator</Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar className='mr-auto'>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/about" activeClassName="active" onClick={this.close}>{t('common:about.title')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/debug" activeClassName='active' onClick={this.close}>{t('common:debug.title')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={ config.get('api').baseUrl + '/api-doc' } target='_blank' rel="noopener noreferrer">API Doc</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/app" activeClassName='active' onClick={this.close}>App</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/app2" activeClassName='active' onClick={this.close}>App 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/app3" activeClassName='active' onClick={this.close}>App 3</NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouterNavLink} exact to="/app4" activeClassName='active' onClick={this.close}>App 4</NavLink>
            </NavItem>
          </Nav>

          <Form inline>
            <span className='mr-2'>
              <SimulatorButtonNew onClick={this.close} />
            </span>
            <span className='mr-2'>
              <LanguageSwitcher useSuspense={false} />
            </span>
            <LoginButton onClick={this.close} />
            <LogoutButton onClick={this.close} />
          </Form>
        </Collapse>
      </Navbar>
    );
  }
}

export default withTranslation()(AppNavbar)