import React from "react";
import { Link } from "react-router-dom";
import { Alert } from 'reactstrap';

export default (props) => {
  return (
    <div className="mx-auto mw-50">
      <Alert color="info">
        <h4 className="alert-heading">Oops, an error occured...</h4>
        <p>
          404, we couldn't find the page {props.location.pathname}
        </p>
        <hr />
        <Link to='/' className="btn btn-info">Go to the homepage</Link>
      </Alert>
    </div>
  )
}