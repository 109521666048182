//import ReactDOM from 'react-dom'
import { library } from '@fortawesome/fontawesome-svg-core'

// import { fas } from '@fortawesome/pro-solid-svg-icons'
// import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'

library.add(
  fal.faEdit
  // far.faEdit,
  // fas.faEdit
);
