
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null
}

export default function(state = initialState, action ) {
  switch(action.type) {
    case "LOGIN_SUCCEEDED":
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
        token: action.token,
      }

    case "LOGOUT_SUCCEEDED":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      }

    default: 
      return state;
  }
}
