import React from 'react';
import ReactDOM from 'react-dom';

import './static/styles.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './store';

// import config from 'react-global-configuration';
// import cachedConfig from './config-cached.json';
// config.set(cachedConfig);

// import config from 'react-global-configuration';

// // const configlyConfig = require('./config');
// // console.log(configlyConfig)

// // config.set(configlyConfig);

// import cachedConfig from './config-cached.json';

// config.set(cachedConfig);

function jwtCallback() {
  store.dispatch({
    type: "CHECK_JWT_TOKEN",
  })
};
const jwtInterval = 60000;

setInterval(jwtCallback, jwtInterval);



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
