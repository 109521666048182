import React from "react";
import { Link } from "react-router-dom";
import { Card, CardTitle, CardText, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'

class SimulatorCard extends React.Component {
  render () {
    const { t, simulator } = this.props;

    let currentUser = null;
    if (this.props.authentication.isAuthenticated && this.props.authentication.user) {
      currentUser = this.props.authentication.user
    }

    const canEdit = (!simulator.userId || (currentUser && currentUser.id === simulator.userId));

    return (
      <Card className="sim-card">
        <CardBody>
          <CardTitle>{simulator.title || 'No title'}</CardTitle>
          <CardText>
            Id: {simulator._id}<br />
            Owner : {simulator.userId}
          </CardText>
          <Link to={`/sim/${simulator._id}`} className="btn btn-primary">{t('simulator:button.open')}</Link>&nbsp;
          {canEdit?
            <Link to={`/sim/${simulator._id}/edit`} className="btn btn-secondary">
              <FontAwesomeIcon icon={["fal", "edit"]} />&nbsp;
              {t('simulator:button.edit')}
            </Link>
          :""}
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProp = storeState => ({
  authentication: storeState.auth
})

export default connect(mapStateToProp)(withTranslation()(SimulatorCard))