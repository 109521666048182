import React from "react";
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Api from '../api';
import { withRouter } from 'react-router-dom';

class SimulatorButtonNew extends React.Component {
  constructor(props) {
    super(props);

    this.onClickNew = this.onClickNew.bind(this);
  }

  onClickNew() {
    Api.newSimulation()
      .then(
        (simulation) => {
          console.log(simulation._id);
          this.props.history.push('/sim/' + simulation._id);
        },
        (error) => {
          console.log(error);
        }
      );

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render () {
    const { t } = this.props;

    return (
      <Button color="success" onClick={this.onClickNew}>{t('simulator:button.new')}</Button>
    )
  }
}

export default withRouter(withTranslation()(SimulatorButtonNew))