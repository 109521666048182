import i18n from "i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import config from 'react-global-configuration';

// the translations
// (tip move them in a JSON file and import them)
// const resources = {
//   en: {
//     translation: {
//       "welcome.react": "Welcome to React and react-i18next"
//     }
//   }
// };

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    load: "languageOnly",
    ns: ['common', 'simulator'],
    defaultNS: 'common',
    fallbackLng: 'en',
    whitelist: ['en', 'fr'],
    //saveMissing: true,
    debug: false,

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: { hash: config.get('i18n').hash }
    },

    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'simulator_lng',
      lookupCookie: 'simulator_lng',
    },

    //resources,
    //lng: "en",

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
