import React from 'react'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
// import './FancyRoute.css'

class LoadingFallback extends React.Component {
  componentWillMount () {
    nprogress.start()
  }

  componentWillUnmount () {
    nprogress.done()
  }

  render () {
    return (
      <div>Loading...</div>
    )
  }
}

export default LoadingFallback