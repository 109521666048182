import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'

class LoginButton extends React.Component {
  render () {
    const loggedIn = this.props.authentication.isAuthenticated;

    if (loggedIn) {
      return "";
    } else {
      return (
        <Link className='btn btn-primary' to="/login" onClick={this.props.onClick}>Login</Link>
      )
    }
  }
}

const mapStateToProp = storeState => ({
  authentication: storeState.auth
})

export default connect(mapStateToProp)(LoginButton)