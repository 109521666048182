import React, { Suspense } from 'react';
import Layout from './view/MyLayout.js'
import PageNotFound from './components/PageNotFound'
//import LoadingRoute from './components/LoadingRoute'
import LoadingFallback from './components/LoadingFallback'
import { Route, Switch } from "react-router-dom";
import './AppConfig';
import './i18n';
//import config from 'react-global-configuration';
//import /*loadable,*/ { lazy } from '@loadable/component'
import {Helmet} from "react-helmet";

//import cachedConfig from './config-cached.json';

import Home from './pages/home';
import About from './pages/about';
// import App1 from './pages/app1';
// import App2 from './pages/app2';
// import App3 from './pages/app3';
//import App4 from './pages/app4';

require( './fontawesome');

//config.set(cachedConfig);


//const Fallback = () => (<div>Loading...</div>);

const lazyComponent = (filename) => {
  return React.lazy(() => {
    // with delay
    // return new Promise(resolve => {
    //   setTimeout(() => resolve(import(`${filename}`)), 2000)
    // });
    return import(`${filename}`);
  }/*, {fallback:<Fallback/>}*/);
}

// const App1 = lazyComponent('./pages/app1');
// const App2 = lazyComponent('./pages/app2');
// const App3 = lazyComponent('./pages/app3');
// const App4 = lazyComponent('./pages/app4');


const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/about",
    exact: true,
    component: About,
    title: "About",
  },
  {
    path: "/debug",
    exact: true,
    component: lazyComponent('./pages/debug'),
    title: "Debug",
  },
  {
    path: "/login",
    exact: true,
    component: lazyComponent('./components/LoginForm'),
    title: "Login",
  },
  {
    path: "/app",
    exact: true,
    component: lazyComponent('./pages/app1'),
    title: "App 1",
  },
  {
    path: "/app2",
    exact: true,
    component: lazyComponent('./pages/app2'),
    title: "App 2",
  },
  {
    path: "/app3",
    exact: true,
    component: lazyComponent('./pages/app3'),
    title: "App 3",
  },
  {
    path: "/app4",
    exact: true,
    component: lazyComponent('./pages/app4'),
    title: "App 4",
  },
  {
    path: "/sim/:id",
    exact: true,
    component: lazyComponent('./components/SimulatorViewPageLoader'),
    title: "App View",
  },
  {
    path: "/sim/:id/edit",
    exact: true,
    component: lazyComponent('./components/SimulatorEditPageLoader'),
    title: "App Edit",
  },
];

function NoMatch({ location }) {
  return (
    <PageNotFound location={location} />
  );
}


function App() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Layout>

        <Switch>
          {routes.map((route, index) => (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Helmet title={ route.title || null } />
                )}
              />
          ))}
        </Switch>

        <Suspense fallback={<LoadingFallback />}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
            ))}

            <Route component={NoMatch} />
          </Switch>
        </Suspense>

      </Layout>
    </Suspense>
  );
}

export default App;
