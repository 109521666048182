import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);

    //console.log(props.i18n)

    this.onClickSwitch = this.onClickSwitch.bind(this)
    this.toggle = this.toggle.bind(this);
    this.onClickItem = this.onClickItem.bind(this);

    this.state = {
      dropdownOpen: false
    };

  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onClickItem(e) {
    const newLng = e.currentTarget.getAttribute('data-lng');
    const currentLng = this.props.i18n.language;

    if (currentLng === newLng) {
      return;
    }

    const allLngs = this.props.i18n.options.whitelist;

    if (!allLngs.includes(newLng)) {
      return;
    }

    if (newLng === 'cimode') {
      return;
    }

    this.props.i18n.changeLanguage(newLng);
  }

  onClickSwitch() {
    const currentLng = this.props.i18n.language;
    const allLngs = this.props.i18n.options.whitelist;
    
    let newLng = null;
    allLngs.map((lng) => {
      if (lng !== currentLng && lng !== 'cimode') {
        newLng = lng;
      }
      return null;
    });

    this.props.i18n.changeLanguage(newLng)
      .then((t) => {
        //console.log(this.props.i18n.language)
      });
  }

  render () {
    const { t, i18n } = this.props;
    const allLngs = i18n.options.whitelist;

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          {t('common:language.'+i18n.language, {lng:i18n.language})}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{t('common:language.pick')}</DropdownItem>
          {allLngs.map((lng) => {
            if (lng === 'cimode') {
              return null;
            }
            return <DropdownItem active={lng === i18n.language} key={lng} data-lng={lng} onClick={this.onClickItem}>{t('common:language.'+lng, {lng:lng})}</DropdownItem>
          })}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default withTranslation()(LanguageSwitcher)