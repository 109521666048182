import config from 'react-global-configuration';
import store from "./store";

function getAuthToken() {
  const state = store.getState();

  if (state && state.auth && state.auth.isAuthenticated && state.auth.token) {
    return state.auth.token;
  }

  return null
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    const error = new Error(response.statusText)
    error.response = response

    return response.json()
      .then(
        (json) => {
          error.originalMessage = json.message;
          throw error
        },
        (err) => {
          throw error
        }
      );
  }
}

const api = {

  getSimulations: function(filter) {
    let apiUrl = config.get('api').baseUrl + '/api/v1/simulations/';

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const authToken = getAuthToken();

    if (authToken) {
      headers['Authorization'] = authToken;
    }

    if (filter) {
      apiUrl = apiUrl + "?filter=" + filter;
    }

    return fetch(apiUrl, {
      method: 'get',
      headers: headers,
    })
      .then(checkStatus)
      .then(res => res.json())
  },

  getSimulation: function(id) {
    const apiUrl = config.get('api').baseUrl + '/api/v1/simulations/' + id;

    return fetch(apiUrl)
      .then(checkStatus)
      .then(res => res.json())
      .then(simulation => {
        return {...simulation, model: JSON.parse(simulation.model)}
      })
  },

  newSimulation: function() {
    const apiUrl = config.get('api').baseUrl + '/api/v1/simulations/new';

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const authToken = getAuthToken();

    if (authToken) {
      headers['Authorization'] = authToken;
    }

    return fetch(apiUrl, {
      method: 'post',
      headers: headers,
    })
      .then(checkStatus)
      .then(res => res.json())
      .then(simulation => {
        return {...simulation, model: JSON.parse(simulation.model)}
      })
  },

  saveSimulation: function(id, {model, title}) {
    const apiUrl = config.get('api').baseUrl + '/api/v1/simulations/' + id;

    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const authToken = getAuthToken();

    if (authToken) {
      headers['Authorization'] = authToken;
    }

    return fetch(apiUrl, {
      method: 'post',
      headers: headers,
      body: JSON.stringify({model: model, title: title})
    })
      .then(checkStatus)
      .then(res => res.json())
      .then(simulation => {
        return {...simulation, model: JSON.parse(simulation.model)}
      })
  },

  saveAsNewSimulation: function({model, title}) {
    const apiUrl = config.get('api').baseUrl + '/api/v1/simulations';

    fetch(apiUrl, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({model: model, title: title})
    })
      .then(checkStatus)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error)
        }
      )
  },

  signin: function(email, password) {
    const apiUrl = config.get('api').baseUrl + '/api/v1/signin';

    return fetch(apiUrl, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email, password})
    })
      .then(checkStatus)
      .then(res => res.json())
  },


}

export default api